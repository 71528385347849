import { useApi }                           from '../utils/api'
import { useCallback, useEffect, useState } from 'react'

let providers
export default function useProviders () {
  const api = useApi()
  const [local, setLocal] = useState(providers)
  const [reload, setReload] = useState(0)

  const reloadFnc = useCallback(() => {
    providers = undefined
    setReload(reload => reload + 1)
  }, [])

  useEffect(() => {
    async function load () {
      providers = (await api.provider.list()).data
      setLocal(providers)
    }

    if (providers === undefined) {
      load()
    }
  }, [reload])

  return [local, reloadFnc]
}