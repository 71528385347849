import PropTypes                    from 'prop-types'
import { Button, Input, TableCell } from '@mui/material'
import React              from 'react'
import Iconify            from '../../components/Iconify'
import { useTranslation } from 'react-i18next'

export default function BillingCodeWidget ({ billCode, setBillCode, onDelete, onPaste }) {

  const { t } = useTranslation()

  const handleChange = (prop) => (event) => {
    handleChangeDirect(prop, event.target.value)
  }

  const handleChangeDirect = (property, value) => {
    setBillCode({
      ...billCode,
      [property]: value
    })
  }

  const onDeleteClick = (id) => (e) => {
    onDelete(id)
  }
  return <React.Fragment>
    <TableCell>
      <Input
        fullWidth
        name={'externalCode'}
        title={t('External code')}
        value={billCode.externalCode}
        onChange={handleChange('externalCode')}
        onPaste={(e) => {
          e.preventDefault()
          onPaste('externalCode', (e.clipboardData || window.clipboardData).getData('text'))
        }}
      />
    </TableCell>
    <TableCell>
      <Input
        fullWidth
        name={'price'}
        title={t('price')}
        value={billCode.price}
        type={'number'}
        step={1}
        onChange={handleChange('price')}
        onPaste={(e) => {
          e.preventDefault()
          onPaste('price', (e.clipboardData || window.clipboardData).getData('text'))
        }}
      />
    </TableCell>
    <TableCell sx={{
      width: '0.1%',
      whiteSpace: 'nowrap'
    }}>
      <Button variant={'text'} color={'error'} onClick={onDeleteClick(billCode.id)}>
        <Iconify icon={'material-symbols:delete-forever'} sx={{ fontSize: 24 }}/>
      </Button>
    </TableCell>
  </React.Fragment>
}

BillingCodeWidget.propTypes = {
  billCode: PropTypes.object.isRequired,
  setBillCode: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onPaste: PropTypes.func.isRequired,
}