import React, { useState }       from 'react'
import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Typography
}                                from '@mui/material'
import BaseTable, { BaseSearch } from '../../components/base-table'
import { useApi }                from '../../utils/api'
import { Link as RouterLink }    from 'react-router-dom'
import { useTranslation }        from 'react-i18next'
import Page                      from '../../components/Page'
import Iconify                   from '../../components/Iconify'

export default function UsersPage() {

  const api = useApi()
  const { t } = useTranslation()
  const [query, setQuery] = useState('')

  const columns = [
    { field: 'id', headerName: 'ID', width: 100, sortable: false, filterable: false },
    { field: 'name', headerName: 'Name', width: 100, sortable: false, filterable: false, renderCell: (cell) => (
        <Link
          component={RouterLink}
          variant="body2"
          to={`/dashboard/users/${cell.row.id}`}
        >
          {cell.row.name}
        </Link>
      )},
    { field: 'name', headerName: 'Name', flex: 1, sortable: false, filterable: false },
    { field: 'email', headerName: 'Email', flex: 1, sortable: false, filterable: false },
  ];

  return (<Page title={t('Users')}>
    <Container>
      <Stack direction="row" alignItems="center" mb={5} spacing={2}>
        <Typography variant="h4" gutterBottom>{t('Users')}</Typography>
        <BaseSearch query={query} onQueryChanged={(query) => setQuery(query)} />
        <Box flexGrow={'1'} />
        <Button variant="contained"
                component={RouterLink}
                to="/dashboard/users/create"
                startIcon={<Iconify icon="material-symbols:add" />}>
          {t('New user')}
        </Button>
      </Stack>
      <BaseTable
        columns={columns}
        query={query}
        loadCallback={async (query, page, pageSize) => {
          try {
            return await api.user.list(query, page, pageSize)
          } catch (e) {
            console.log('Failed to load users', e)
            return []
          }
        }}
      />
    </Container>
  </Page>)

}