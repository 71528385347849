// component
import Iconify       from '../../components/Iconify';
import * as React    from 'react'

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'services',
    path: '/dashboard/services',
    icon: getIcon('material-symbols:list-alt'),
  },
  {
    title: 'messages',
    path: '/dashboard/messages',
    icon: getIcon('material-symbols:sms'),
  },
  {
    title: 'errors',
    path: '/dashboard/errors',
    icon: getIcon('material-symbols:error'),
  },
  { divider: true, key: 'key-divider-1' },
  {
    title: 'providers',
    path: '/dashboard/providers',
    icon: getIcon('material-symbols:settings-input-antenna'),
  },
  {
    title: 'categories',
    path: '/dashboard/categories',
    icon: getIcon('material-symbols:format-list-bulleted'),
  },
  {
    title: 'users',
    path: '/dashboard/users',
    icon: getIcon('eva:people-fill'),
  },
  { divider: true, key: 'key-divider-0' },
  {
    title: 'phone whitelist',
    path: '/dashboard/whitelist',
    icon: getIcon('material-symbols:person-check'),
  },
  {
    title: 'phone blacklist',
    path: '/dashboard/blacklist',
    icon: getIcon('material-symbols:person-cancel'),
  },
];

export default navConfig;
