import { DateTime } from 'luxon'

export const USER_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm:ss'
export const INPUT_DATE_TIME_FORMAT = 'yyyy-MM-dd\'T\'HH:mm'
export const SQL_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'


export const parseUTC = (str) => {
  return DateTime.fromISO(str)
}

export const dateToUserString = (str) => {
  return DateTime.fromISO(str).setZone('local').toFormat(USER_DATE_TIME_FORMAT)
}

export const dateToInputString = (str) => {
  return DateTime.fromISO(str).setZone('local').toFormat(INPUT_DATE_TIME_FORMAT)
}

export const dateFromInputStringToISO = (str) => {
  return DateTime.fromFormat(str, INPUT_DATE_TIME_FORMAT).toUTC().toISO()
}
export const dateFromInputStringToSql = (str) => {
  return DateTime.fromFormat(str, INPUT_DATE_TIME_FORMAT).toUTC().toFormat(SQL_DATE_TIME_FORMAT)
}