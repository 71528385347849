module.exports = Object.freeze({
  MessageStatus: {
    scheduled: 'scheduled',
    expired: 'expired',
    processed: 'processed',
    delivered: 'delivered',
    notDelivered: 'not_delivered',
    notAccepted: 'not_accepted',
    monthlyLimitExceeded: 'limit_exceeded',
    lowCredit: 'low_credit',
  },
  Service: {
    type_microPayment: 'micropayments',
    type_premium: 'premium',
  },
  Provider: {
    type_microPayment: 'micropayments',
    type_premium: 'premium',
  }
})