import React, { useEffect, useState } from 'react'
import PropTypes                      from 'prop-types'
import { Link as RouterLink }         from 'react-router-dom'
import { useTranslation }             from 'react-i18next'
import {
  Avatar,
  Box,
  Card, CardContent, CardHeader,
  Container, Divider,
  Grid, Link,
  Typography,
}                           from '@mui/material'
import { useApi }           from '../../utils/api'
import { useParams }        from 'react-router-dom'
import Page                 from '../../components/Page'
import format               from 'xml-formatter'
import Iconify              from '../../components/Iconify'
import { dateToUserString } from '../../utils/dateutils'
import useProviders         from '../../hooks/useProviders'
import BaseTable            from '../../components/base-table'

const XML_EMPTY = '<?xml version="1.0" encoding="UTF-8"?><message></message>'

function InfoRow ({ title, text }) {

  return (<Box sx={{ display: 'flex', flexDirection: 'row', my: 1 }}>

    <Typography variant={'subtitle2'} color={'text.secondary'} sx={{ flex: '0 0 100px', textAlign: 'right', mr: 1 }}>
      {title}
    </Typography>
    <Typography variant={'body1'}>{text}</Typography>
  </Box>)
}

InfoRow.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default function MessagePage () {

  const api = useApi()
  const [message, setMessage] = useState({})
  const { messageId } = useParams()
  const [providers] = useProviders()
  const { t } = useTranslation()

  useEffect(() => {
    (async () => {
      let msg = await api.message.detail(messageId).catch(() => ({}))
      setMessage(msg)
    })()
  }, [messageId])

  const logColumns = [
    { field: 'id', headerName: t('ID'), width: 100, sortable: false, filterable: false },
    { field: 'messageId', headerName: t('Message ID'), width: 100, sortable: false, filterable: false, renderCell: (cell) => {
        return cell.row.messageId && <Link
          component={RouterLink}
          variant="body2"
          to={`/messages/${cell.row.messageId}`}
        >
          {cell.row.messageId}
        </Link>
      }},
    { field: 'created', headerName: t('Created'), width: 140, sortable: false, filterable: false, renderCell: (cell) => dateToUserString(cell.row.created) },
    { field: 'text', headerName: t('Text'), flex: 1, sortable: false, filterable: false, editable: true, },
    { field: 'rawMessage', headerName: t('Raw message'), flex: 2, sortable: false, filterable: false, editable: true, },
  ];

  let providerText = providers?.find(prov => prov.id === message?.providerId)?.name ?? `${message?.providerId}`

  return (
    <Page title={'Message'}>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography component="h1" variant="h3" sx={{ flexGrow: 1, mb: 2 }}>
          Message #{messageId}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {!!message.id && <Card>
              <CardHeader
                avatar={
                  <Avatar>
                    <Iconify icon={'material-symbols:sms'} />
                  </Avatar>
                }
                title={`${message.telFrom}`}
                subheader={message.created ? dateToUserString(message.created) : '---'}
              />
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {t('General info')}
                </Typography>
                <Divider/>
                <InfoRow title={t('ID')} text={`${message.id}`}/>
                <InfoRow title={t('Foreign ID')} text={`${message.foreignId}`}/>
                {message?.parentId && <Box sx={{ display: 'flex', flexDirection: 'row', my: 1 }}>
                  <Typography variant={'subtitle2'} color={'text.secondary'} sx={{ flex: '0 0 100px', textAlign: 'right', mr: 1 }}>{t('Parent SMS')}</Typography>
                  <Link component={RouterLink} to={`/dashboard/messages/${message.parentId}`}>{message.parentId}</Link>
                </Box>}
                <InfoRow title={t('Status')} text={message.status ?? t('Scheduled')}/>
                <InfoRow title={t('Received')} text={message.received ? dateToUserString(message.received) : '---'}/>
                <InfoRow title={t('Scheduled')} text={message.scheduled ? dateToUserString(message.scheduled) : t('Immediately')}/>
                <InfoRow title={t('Expired')} text={message.expired ? dateToUserString(message.expired) : '---'}/>
                <InfoRow title={t('Tel. from')} text={`${message.telFrom}`}/>
                <InfoRow title={t('Tel. to')} text={`${message.telTo}`}/>
                <InfoRow title={t('Provider')} text={providerText}/>
                <InfoRow title={t('Operator')} text={message.operator}/>
                <InfoRow title={t('Attempts')} text={`${message.attempts} x`}/>
              </CardContent>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {t('Content')}
                </Typography>
                <Divider />
                <Typography variant="body1" my={1}>
                  {message.content}
                </Typography>
              </CardContent>
            </Card>}
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  {t('Raw message')}
                </Typography>
                <Divider/>
                <Box component={'pre'} p={2} sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                  {message.rawMessageType === 'xml'
                    ? format(message?.rawMessage ?? XML_EMPTY, {
                      collapseContent: true
                    })
                    : message.rawMessage}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h4'}>{t('Logs')}</Typography>
            <BaseTable
              columns={logColumns}
              autoHeight={true}
              loadCallback={async (query, page, pageSize) => {
                try {
                  return await api.messageLog.listForMessage(messageId)
                } catch (e) {
                  console.log('Failed to load message logs', e)
                  return []
                }
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )

}