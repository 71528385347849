import React, { useEffect, useRef, useState } from 'react'
import { useApi }                             from '../../utils/api'
import BillingCodeWidget                      from './BillingCodeWidget'
import {
  Button,
  Grid, LinearProgress,
  Paper, Stack,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
}                                             from '@mui/material'
import PropTypes                              from 'prop-types'
import { useSnackbar }                        from 'notistack'
import { LoadingButton }                      from '@mui/lab'
import { useTranslation }                     from 'react-i18next'
import { TableVirtuoso }                      from 'react-virtuoso'
import Iconify                                from '../../components/Iconify'

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

export default function BillingCodeListWidget ({ providerId }) {

  const api = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [billCodes, setBillCodes] = useState([])
  const [serverBillCodes, setServerBillCodes] = useState([])
  const [deletedBillCodeIds, setDeletedBillCodeIds] = useState([])
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [forceReloadCounter, setForceReloadCounter] = useState(0)
  const virtuoso = useRef(null);

  useEffect(() => {
    (async () => {
      setLoading(() => true)
      let codes = (await api.billCode.list(providerId)).data ?? []
      setBillCodes(codes.sort((a, b) => a.price < b.price ? -1 : (a.price === b.price ? 0 : 1)))
      setServerBillCodes(codes)
      setLoading(() => false)
    })()

  }, [forceReloadCounter])

  const onBtnAddClick = (e) => {
    setBillCodes((prevBillCodes) => {
      return [...prevBillCodes, {
        id: '',
        providerId: parseInt(providerId),
        externalCode: '',
        price: 0,
      }]
    })
    virtuoso.current.scrollToIndex({
      index: billCodes.length,
    });
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setSaving(true)
    const existedMaps = new Map(serverBillCodes.map(bc => [bc.id, bc]))
    const updatedItems = billCodes.filter(bc => {
      const existed = !!bc.id ? existedMaps.get(bc.id) : null
      return existed === null || existed.price !== bc.price || existed.externalCode !== bc.externalCode
    })
    let totalCount = deletedBillCodeIds.length + updatedItems.length
    let successCount = 0
    // first remove deleted billing codes
    for (const billCodeId of deletedBillCodeIds) {
      try {
        await api.billCode.delete(billCodeId)
        successCount++
      } catch (e) {
        enqueueSnackbar(t('bill-code-delete-failed', { error: e.error }), {
          variant: 'error'
        })
      }
    }
    // then save new billing codes or update existing
    for (const billCode of updatedItems) {
      try {
        let response = await api.billCode.save(billCode)
        successCount++
      } catch (e) {
        enqueueSnackbar(t('bill-code-save-failed', { error: e.error }), {
          variant: 'error'
        })
      }
    }
    enqueueSnackbar(t('bill-code-save-success', { success: successCount, total: totalCount }), {
      variant: totalCount === successCount ? 'success' : 'warning'
    })
    setSaving(false)
    setForceReloadCounter((prev) => prev + 1)
  }

  const onPasteImpl = (index, column, data) => {
    const rows = data.split('\n').filter(it => !!it)
    const values = rows.map(row => row.split(/\s/))
    let begin = billCodes.slice(0, index+1)
    let end = billCodes.slice(index+1)
    let updated = values.map((row, index) => ({ id: '', price: row[1], externalCode: row[0], providerId: parseInt(providerId) }))
    setBillCodes((prev) => begin.concat(updated).concat(end))
  }

  return (
    <Grid component={'form'} onSubmit={onSubmit} item xs={12}>
      <Typography variant="h4" gutterBottom>{t('Billing codes')}</Typography>
      <Typography variant="caption" display="block" gutterBottom>
        Copy and paste csv file of 2 columns: 0-external code; 1-price in euro cent<br/>
        Example:<br/><br/>
        PRK0 100<br/>
        PRK1 120
      </Typography>
      {loading && <LinearProgress />}

      <Paper style={{ height: '50vh', width: '100%' }}>
        <TableVirtuoso
          ref={virtuoso}
          data={billCodes}
          components={VirtuosoTableComponents}
          fixedHeaderContent={() => (<TableRow sx={{ bgcolor: 'common.white' }}>
              <TableCell>{t('External code')}</TableCell>
              <TableCell>{t('Price')}</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>)}
          itemContent={(index, billCode) => (<BillingCodeWidget
            key={`bill-code-${index}`}
            billCode={billCode}
            setBillCode={(newBillCode) => {
              setBillCodes(prevState => {
                prevState[index] = newBillCode
                return [...prevState]
              })
            }}
            onDelete={(id) => {
              if (id) {
                deletedBillCodeIds.push(id)
              }
              setBillCodes(prevState => {
                prevState.splice(index, 1)
                return [...prevState]
              })
            }}
            onPaste={(column, data) => {
              onPasteImpl(index, column, data)
            }}/>)}
        />
      </Paper>
      <Stack direction={'horizontal'} sx={{ mt: 2, px: 1, gap: 2 }}>
        <LoadingButton type={'submit'} variant={'contained'} loading={saving}>{t('Save billing codes')}</LoadingButton>
        <Button variant={'outlined'}
                onClick={onBtnAddClick}
                startIcon={<Iconify icon="material-symbols:add"/>}>
          {t('Define new billing code')}
        </Button>
      </Stack>
    </Grid>)
}

BillingCodeListWidget.propTypes = {
  providerId: PropTypes.number.isRequired,
}