import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom'
// layouts
import DashboardLayout                      from './layouts/dashboard'
//
import UserPage from './ui/user/UserPage'
import NotFound from './ui/NotFoundPage.js'
import SignInPage            from './ui/user/SignInPage'
import React, { useContext } from 'react'
import UsersPage             from './ui/user/UsersPage'
import { AppContext }        from './App'
import ForbiddenPage  from './ui/ForbiddenPage'
import ServicePage       from './ui/service/ServicePage'
import ServicesPage      from './ui/service/ServicesPage'
import MessagesPage      from './ui/message/MessagesPage'
import MessagePage       from './ui/message/MessagePage'
import Providers         from './ui/provider/Providers'
import ProviderPage      from './ui/provider/ProviderPage'
import BlacklistPage     from './ui/listed/BlacklistPage'
import CategoriesPage    from './ui/category/CategoriesPage'
import CategoryPage      from './ui/category/CategoryPage'
import ErrorsPage        from './ui/ErrorsPage'
import WhitelistPage     from './ui/listed/WhitelistPage'
import WhitelistItemPage from './ui/listed/WhitelistItemPage'
import BlackListItemPage from './ui/listed/BlacklistItemPage'

// ----------------------------------------------------------------------

const ProtectedRoute = ({ children }) => {

  const location = useLocation()
  const { user } = useContext(AppContext)

  if (user === null) {
    return <Navigate to="/sign-in" state={{ from: location }}/>
  }

  return (<React.Fragment>{children}</React.Fragment>)
}

export default function Router () {

  return useRoutes([
    {
      path: '/dashboard',
      element: <ProtectedRoute><DashboardLayout/></ProtectedRoute>,
      children: [
        { path: 'services/:serviceId', element: <ProtectedRoute><ServicePage/></ProtectedRoute> },
        { path: 'services', element: <ProtectedRoute><ServicesPage/></ProtectedRoute> },
        { path: 'users/:userId', element: <ProtectedRoute><UserPage /></ProtectedRoute> },
        { path: 'users', element: <ProtectedRoute><UsersPage /></ProtectedRoute> },
        { path: 'messages/:messageId', element: <ProtectedRoute><MessagePage /></ProtectedRoute> },
        { path: 'messages', element: <ProtectedRoute><MessagesPage /></ProtectedRoute> },
        { path: 'providers/:providerId', element: <ProtectedRoute><ProviderPage /></ProtectedRoute> },
        { path: 'providers', element: <ProtectedRoute><Providers /></ProtectedRoute> },
        { path: 'blacklist/add', element: <ProtectedRoute><BlackListItemPage /></ProtectedRoute> },
        { path: 'blacklist/:blacklistItemId', element: <ProtectedRoute><BlackListItemPage /></ProtectedRoute> },
        { path: 'blacklist', element: <ProtectedRoute><BlacklistPage /></ProtectedRoute> },
        { path: 'whitelist/add', element: <ProtectedRoute><WhitelistItemPage /></ProtectedRoute> },
        { path: 'whitelist/:whitelistItemId', element: <ProtectedRoute><WhitelistItemPage /></ProtectedRoute> },
        { path: 'whitelist', element: <ProtectedRoute><WhitelistPage /></ProtectedRoute> },
        { path: 'categories', element: <ProtectedRoute><CategoriesPage /></ProtectedRoute> },
        { path: 'category/:categoryId', element: <ProtectedRoute><CategoryPage /></ProtectedRoute> },
        { path: 'errors', element: <ProtectedRoute><ErrorsPage /></ProtectedRoute> },
        { path: '404', element: <NotFound/> },
        { path: 'forbidden', element: <ForbiddenPage/> },
        { path: '*', element: <Navigate to="/dashboard/404" replace={true} /> },
      ],
    },
    {
      path: '/',
      element: <Outlet />,
      children: [
        { path: '/sign-in', element: <SignInPage/> },
        { path: '*', element: <Navigate to="/dashboard/404" replace={true} /> },
      ],
    },
    { index: true, element: <Navigate to="/dashboard/messages" replace={true} /> },
  ])
}
