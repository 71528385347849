import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline                    from '@mui/material/CssBaseline';
import TextField                      from '@mui/material/TextField';
import FormControlLabel               from '@mui/material/FormControlLabel';
import Checkbox                       from '@mui/material/Checkbox';
import Link                           from '@mui/material/Link';
import Grid                           from '@mui/material/Grid';
import Box                            from '@mui/material/Box';
import LockOutlinedIcon               from '@mui/icons-material/LockOutlined';
import Typography                     from '@mui/material/Typography';
import Container                      from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useApi }                     from '../../utils/api'
import { useNavigate }                from 'react-router-dom'
import MediatexLogo                   from '../../assets/img/logo_250_55.png';
import { Alert }                      from '@mui/material'
import { useContext, useState }       from 'react'
import { AppContext }                 from '../../App'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'© Copyright '}
      <Link color="inherit" href="https://mediatex.sk/">
        Mediatex
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInPage() {

  const navigate = useNavigate()
  const api = useApi()
  const {user, setUser} = useContext(AppContext)
  const [loginError, setLoginError] = useState(null)

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    api.user.login(data.get('email'), data.get('password'))
      .then(user => {
        setUser(user)
        navigate('/')
      })
      .catch(error => {
        setLoginError(error)
      })
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            sx={{
              mb: { xs: 2, md: 5 },
            }}
            alt="Mediatex logo"
            src={MediatexLogo}
          />
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onKeyDown={(e) => setLoginError(null)}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onKeyDown={(e) => setLoginError(null)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            {loginError && <Alert severity="error">Unable to login, please try again.</Alert>}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}