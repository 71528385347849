import { useApi }                from '../../utils/api'
import { useTranslation }        from 'react-i18next'
import React, { useState }       from 'react'
import BaseTable, { BaseSearch } from '../../components/base-table'
import {
  Box, Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
}                                from '@mui/material'
import { Link as RouterLink }    from 'react-router-dom'
import {
  dateFromInputStringToSql,
  dateToUserString,
}                                from '../../utils/dateutils'
import Iconify                   from '../../components/Iconify'
import Page                      from '../../components/Page'
import { MessageStatus }         from '../../consts'

export default function MessagesPage () {

  const api = useApi()
  const { t } = useTranslation()
  const [query, setQuery] = useState("")
  const [status, setStatus] = useState("")
  const [dateTimeFrom, setDateTimeFrom] = useState("")
  const [dateTimeTo, setDateTimeTo] = useState("")
  const [showFilter, setShowFilter] = useState(true);
  const [forceReloadCounter, setForceReloadCounter] = useState(0);

  const columns = [
    { field: 'id', headerName: t('ID'), width: 70, sortable: false, filterable: false, renderCell: (cell) => (
        <RouterLink
          to={`/dashboard/messages/${cell.row.id}`}
        >
          {cell.row.id}
        </RouterLink>
      ), },
    { field: 'created', headerName: t('Created'), width: 170, sortable: false, filterable: false, renderCell: (cell) => {
      return (<Typography color={'color.secondary'}>{dateToUserString(cell.row.created)}</Typography>)
    }},
    { field: 'status', headerName: t('Status'), width: 120, sortable: false, filterable: false, renderCell: (cell) => {
      return t(cell.row.status ?? 'scheduled');
      } },
    { field: 'telFrom', headerName: t('Phone'), width: 190, sortable: false, filterable: false, renderCell: (cell) => {
      return (<Stack direction={'row'} spacing={2} alignItems={'center'}>
        <Typography variant={'string'} color={'color.secondary'}>{cell.row.telFrom}</Typography>
        <Iconify icon={'material-symbols:arrow-forward'} />
        <Typography variant={'string'} color={'color.secondary'}>{cell.row.telTo}</Typography>
      </Stack>);
      } },
    { field: 'content', headerName: t('Content'), flex: 1, sortable: false, filterable: false },
  ];

  const statusField = () => (<>
    <FormControl sx={{  minWidth: 120 }}>
      <InputLabel id="label-status">{t('Status')}</InputLabel>
      <Select
        labelId="label-status"
        value={status}
        onChange={(e) => {
          setStatus(e.target.value)
        }}
        label="Status"
      >
        <MenuItem value="">
          <em>{t('All')}</em>
        </MenuItem>
        {
          Object.keys(MessageStatus).map((key) => {
            return <MenuItem key={key} value={key}>{t(MessageStatus[key])}</MenuItem>
          })
        }
      </Select>
    </FormControl>
  </>)

  const onSubmitSearch = () => {
    setForceReloadCounter(forceReloadCounter + 1);
  }

  return (<Page title={'Messages'}>
    <Container>
      <Stack direction="row" alignItems="center" mb={1} spacing={2}>
        <Typography variant="h4" gutterBottom>{t('Messages')}</Typography>
        <Box flexGrow={1} />
        <IconButton onClick={(e) => {
          setShowFilter(!showFilter)
        }} >
          <Iconify icon={showFilter ? 'eva:funnel-fill' : 'eva:funnel-outline'} />
        </IconButton>
      </Stack>
      {showFilter && <>
        <Stack direction={'row'} justifyContent={'space-between'} mb={5} spacing={2}
          component={'form'} onSubmit={(e) => {
            e.preventDefault();
            onSubmitSearch();
          }}>
          <TextField
            id="datetime-local"
            label={t('From')}
            type="datetime-local"
            value={dateTimeFrom}
            onChange={(e) => {
              setDateTimeFrom(e.target.value)
            }}
            sx={{ minWidth: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="datetime-local"
            label={t('To')}
            type="datetime-local"
            value={dateTimeTo}
            onChange={(e) => {
              setDateTimeTo(e.target.value)
            }}
            sx={{ minWidth: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {statusField()}
          <BaseSearch query={query} onQueryChanged={(query) => setQuery(query)} />
          <Button type={'submit'} variant="outlined">{t('Search')}</Button>
        </Stack>
      </>}
      <BaseTable
        columns={columns}
        query={query}
        forceReloadCounter={forceReloadCounter}
        loadCallback={async (query, page, pageSize) => {
          try {
            return await api.message.list({
              query: query,
              from: dateTimeFrom ? dateFromInputStringToSql(dateTimeFrom) : null,
              to: dateTimeTo ? dateFromInputStringToSql(dateTimeTo) : null,
              status: status,
            }, page, pageSize)
          } catch (e) {
            console.log('Failed to load messages', e)
            return []
          }
        }} />
    </Container>
  </Page>)

}