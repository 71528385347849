import React, { useEffect, Suspense } from 'react'
import { useApi, useCurrentUser }     from './utils/api'
import { SnackbarProvider }           from 'notistack'
import ThemeProvider                  from './theme'
import Router                         from './routes'

export const AppContext = React.createContext({
  user: null,
});

function App() {

  const api = useApi()
  const [user, setUser] = useCurrentUser()

  useEffect(() => {
    if (user !== null) {
      api.user.validateHash()
        .then(validateUser => { setUser({
            ...validateUser,
            accessToken: user.accessToken
        }) })
        .catch(err => {
          setUser(null)
        })
    }
  }, [])

  return (<Suspense fallback="loading">
    <ThemeProvider>
      <AppContext.Provider value={{ user: user, setUser: setUser }}>
        <SnackbarProvider>
          <Router />
        </SnackbarProvider>
      </AppContext.Provider>
    </ThemeProvider>
  </Suspense>)

}

export default App;