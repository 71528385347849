import React, { useState }            from 'react'
import {
  Box,
  Button,
  Chip,
  Container, Link,
  Stack,
  Typography
}                                     from '@mui/material'
import BaseTable, { BaseSearch }      from '../../components/base-table'
import { useApi }                     from '../../utils/api'
import { Link as RouterLink }         from 'react-router-dom'
import { useTranslation }             from 'react-i18next'
import { dateToUserString, parseUTC } from '../../utils/dateutils'
import Page                           from '../../components/Page'
import Iconify                        from '../../components/Iconify'

export default function ServicesPage() {

  const api = useApi()
  const { t } = useTranslation()
  const [query, setQuery] = useState('')

  const columns = [
    { field: 'id', headerName: t('ID'), width: 100, sortable: false, filterable: false },
    { field: 'name', headerName: t('Name'), flex: 1, sortable: false, filterable: false, renderCell: (cell) => (
        <Link
          component={RouterLink}
          variant="body2"
          to={`/dashboard/services/${cell.row.id}`}
        >
          {cell.row.name}
        </Link>
      )},
    { field: 'pattern', headerName: t('Pattern'), flex: 1, sortable: false, filterable: false, renderCell: (service) => {
      return (
        <Box component="span" sx={{ letterSpacing: '1.5px', fontSize: '.80em', fontWeight: '500' }}>
          {`${service.row.number} - ${service.row.pattern}`}
        </Box>
      )
      }
    },
    { field: 'enabled', headerName: t('Enabled'), width: 100, sortable: false, filterable: false, renderCell: (cell) => {
        return (
          <Chip color={cell.value ? 'success' : 'error'} size="small" variant={'outlined'} label={t(cell.value ? 'Enabled' : 'Disabled')} />
        )
      }
    },
    { field: 'activeFrom', headerName: t('Active'), flex: 2, sortable: false, filterable: false, renderCell: (cell) => {
        let from = parseUTC(cell.row.activeFrom)
        let to = parseUTC(cell.row.activeTo)
        let active = from.diffNow() < 0 && to.diffNow() > 0
        return (
          <Chip color={active ? 'success' : 'warning'} size="small" variant={'outlined'} label={`${dateToUserString(cell.row.activeFrom)} - ${dateToUserString(cell.row.activeTo)}`} />
        )
      }
    },
  ];

  return (<Page title={'Services'}>
    <Container>
      <Stack direction="row" alignItems="center" mb={5} spacing={2}>
        <Typography variant="h4" gutterBottom>{t('Services')}</Typography>
        <BaseSearch query={query} onQueryChanged={(query) => setQuery(query)} />
        <Box flexGrow={'1'} />
        <Button variant="contained"
                component={RouterLink}
                to="/dashboard/services/create"
                startIcon={<Iconify icon="material-symbols:add" />}>
          {t('New service')}
        </Button>
      </Stack>
      <BaseTable
        columns={columns}
        query={query}
        loadCallback={async (query, page, pageSize) => {
          try {
            return await api.service.list(query, page, pageSize)
          } catch (e) {
            console.log('Failed to load services', e)
            return []
          }
        }}
      />
    </Container>
  </Page>)

}