import React, { useEffect, useState }                       from 'react'
import { useTranslation }                                          from 'react-i18next'
import { Box, Button, Container, Grid, Paper, Stack, Typography, } from '@mui/material'
import AlertSimpleDialog                                           from '../../components/alert-simple-dialog'
import { useApi }                                           from '../../utils/api'
import { useNavigate, useParams }                           from 'react-router-dom'
import { useSnackbar }                                      from 'notistack'
import ShortFormControl                                     from '../../components/short-form-control'
import Page                                                 from '../../components/Page'

export default function WhitelistItemPage () {

  const api = useApi()
  const navigate = useNavigate()
  const { whitelistItemId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [values, setValues] = useState({
    telNumber: '',
    note: '',
  })
  const [shownDeleteDialog, setShownDeleteDialog] = useState(false)

  useEffect(() => {
    (async () => {
      if (!isNaN(whitelistItemId)) {
        let item = await api.whitelist.detail(whitelistItemId).catch(() => ({}))
        setValues({
          ...item,
        })
      }
    })()
  }, [whitelistItemId])

  const handleCloseDeleteDialog = (result) => {
    setShownDeleteDialog(false)
    if (result) {
      api.whitelist.delete(whitelistItemId).then(() => {
        enqueueSnackbar(t('Item deleted from whitelist'), {
          variant: 'success'
        })
        navigate('/dashboard/whitelist')
      }).catch(error => {
        enqueueSnackbar(t('Failed to delete item from whitelist'), {
          variant: 'error'
        })
      })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = {
      ...values,
    }
    try {
      let item = await api.whitelist.save(data)
      navigate(`/dashboard/whitelist/${item.id}`)
      enqueueSnackbar(t('Whitelist item saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save whitelist item'), {
        variant: 'error'
      })
    }
  }

  const handleChange = (prop) => (event) => {
    handleChangeDirect(prop, event.target.value)
  }

  const handleChangeDirect = (prop, value) => {
    setValues({ ...values, [prop]: value })
  }

  return (
    <Page title={t('Whitelist item')}>
      <Stack direction="row" alignItems="center" mb={5} spacing={2}>
        <Typography variant="h4" gutterBottom>{t('Whitelist item')}</Typography>
      </Stack>
      <Container component={Paper} sx={{ p: 2, }}>
        <Grid container component={'form'} onSubmit={onSubmit} xs={12} spacing={2}>
          <Grid item xs={12}>
            <ShortFormControl
              name={'telNumber'}
              title={t('Tel. number')}
              value={values.telNumber}
              handleChange={handleChange}/>
          </Grid>
          <Grid item xs={12}>
            <ShortFormControl
              name={'note'}
              title={t('Note')}
              value={values.note}
              handleChange={handleChange}/>
          </Grid>
          <Grid item xs={12}>
            <Button type={'submit'} variant={'contained'}>{t('Save')}</Button>
            {!isNaN(whitelistItemId) && (<Button type={'button'} color={'error'} variant={'contained'} sx={{ ml: 2 }}
                                                 onClick={() => { setShownDeleteDialog(true) }}>{t('Delete')}</Button>)}
          </Grid>
        </Grid>
        <AlertSimpleDialog
          title={t('Remove item from whitelist')}
          message={t('delete-confirm-msg', { name: values.telNumber })}
          open={shownDeleteDialog}
          onClose={handleCloseDeleteDialog}
        />
      </Container>
    </Page>
  )

}