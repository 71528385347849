import { useContext, useRef, useState } from 'react'
import { useNavigate }                  from 'react-router-dom'
// @mui
import { alpha }                        from '@mui/material/styles'
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  ListItemText,
  ListItemIcon
}                                       from '@mui/material'
// components
import MenuPopover                      from '../../components/MenuPopover'
import { t }                            from 'i18next'
import Iconify                          from '../../components/Iconify'
import { AppContext }                   from '../../App'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);

  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  const { user, setUser, setCurrentApp }  = useContext(AppContext);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar>{user?.name[0]}</Avatar>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={(e) => {
          handleClose()
          setUser(null)
          setCurrentApp(null)
          navigate('/sign-in')
        }} sx={{ m: 1 }}>
          <ListItemIcon>
            <Iconify icon="eva:log-out-fill" />
          </ListItemIcon>
          <ListItemText>{t('Logout')}</ListItemText>
        </MenuItem>
      </MenuPopover>
    </>
  );
}
