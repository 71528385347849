import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Paper,
  Switch,
}                                 from '@mui/material'
import { useTranslation }         from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useApi }                 from '../../utils/api'
import { useSnackbar }            from 'notistack'
import AlertSimpleDialog          from '../../components/alert-simple-dialog'

export default function UserPage() {

  const api = useApi()
  const navigate = useNavigate()
  const { userId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [showPasswordPanel, setShowPasswordPanel] = useState(isNaN(userId))
  const [shownDeleteDialog, setShownDeleteDialog] = useState(false)

  useEffect(() => {
    (async () => {
      if (!isNaN(userId)) {
        let user = await api.user.detail(userId)
        setValues({
          name: '',
          email: '',
          password: '',
          confirmPassword: '',
          ...user
        })
      }
    })()
  }, [userId])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }

  const handleCloseDeleteDialog = (result) => {
    setShownDeleteDialog(false)
    if (result) {
      api.user.delete(userId).then(() => {
        enqueueSnackbar(t('User deleted'), {
          variant: 'success'
        })
        navigate('/users')
      }).catch(error => {
        enqueueSnackbar(t('Failed to delete user'), {
          variant: 'error'
        })
      })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = { ...values }
    if (!showPasswordPanel) {
      // if the password is not changed, ignore it
      delete data.password
      delete data.confirmPassword
    }
    try {
      let user = await api.user.save(data)
      navigate(`/users/${user.id}`)
      enqueueSnackbar(t('User saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save user'), {
        variant: 'error'
      })
    }
  }

  return (
      <Container container component="main">
        <Grid component={'form'} onSubmit={onSubmit} item xs={12}>
          <Paper sx={{ p: 2, width: '100%' }}>
            <Grid container sx={{ width: '100%' }} spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="user-name">{t('Name')}</InputLabel>
                  <Input
                    id="user-name"
                    value={values.name}
                    onChange={handleChange('name')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="user-email">{t('Email')}</InputLabel>
                  <Input
                    id="user-email"
                    value={values.email}
                    disabled={!isNaN(userId)}
                    onChange={handleChange('email')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch
                    checked={showPasswordPanel}
                    onChange={(e) => setShowPasswordPanel(!showPasswordPanel)}
                    disabled={isNaN(userId)}
                  />}
                  label={t('Set password')} />
              </Grid>
              { showPasswordPanel && <>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="user-password">{t('Password')}</InputLabel>
                    <Input
                      id="user-password"
                      type={'password'}
                      value={values.password}
                      onChange={handleChange('password')}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="user-password-confirm">{t('Confirm password')}</InputLabel>
                    <Input
                      id="user-password-confirm"
                      type={'password'}
                      value={values.confirmPassword}
                      onChange={handleChange('confirmPassword')}
                    />
                  </FormControl>
                </Grid>
              </>}
            </Grid>
          </Paper>
          <Box sx={{ mt: 2, px: 1 }}>
            <Button type={'submit'} variant={'contained'}>{t('Save')}</Button>
            { !isNaN(userId) && (<Button type={'button'} color={'error'} variant={'contained'} sx={{ ml: 2 }} onClick={() => { setShownDeleteDialog(true) }}>{t('Delete')}</Button>) }
          </Box>
        </Grid>
        <AlertSimpleDialog
          title={t('Deleting user')}
          message={t('delete-confirm-msg', { name: values.name })}
          open={shownDeleteDialog}
          onClose={handleCloseDeleteDialog}
        />
      </Container>
  )
}