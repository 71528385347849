import React, { useEffect, useState }                                                      from 'react'
import { useTranslation }                                                                  from 'react-i18next'
import { Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, } from '@mui/material'
import AlertSimpleDialog
                                                                                           from '../../components/alert-simple-dialog'
import { useApi }                                                                          from '../../utils/api'
import { useNavigate, useParams }                                                          from 'react-router-dom'
import { useSnackbar }                                                                     from 'notistack'
import ShortFormControl
                                                                                           from '../../components/short-form-control'
import BillingCodesListWidget
                                                                                           from './BillingCodeListWidget'

export default function ProviderPage () {

  const api = useApi()
  const navigate = useNavigate()
  const { providerId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [values, setValues] = useState({
    name: '',
    uid: '',
    type: '',
    user: '',
    password: '',
  })
  const [shownDeleteDialog, setShownDeleteDialog] = useState(false)

  useEffect(() => {
    (async () => {
      if (!isNaN(providerId)) {
        let provider = await api.provider.detail(providerId).catch(() => ({}))
        setValues({
          password: '',
          ...provider,
        })
      }
    })()
  }, [providerId])

  const handleCloseDeleteDialog = (result) => {
    setShownDeleteDialog(false)
    if (result) {
      api.provider.delete(providerId).then(() => {
        enqueueSnackbar(t('Provider deleted'), {
          variant: 'success'
        })
        navigate('/providers')
      }).catch(error => {
        enqueueSnackbar(t('Failed to delete provider'), {
          variant: 'error'
        })
      })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = {
      ...values,
    }
    try {
      let provider = await api.provider.save(data)
      navigate(`/dashboard/providers/${provider.id}`)
      enqueueSnackbar(t('Provider saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save provider'), {
        variant: 'error'
      })
    }
  }

  const handleChange = (prop) => (event) => {
    handleChangeDirect(prop, event.target.value)
  }

  const handleChangeDirect = (prop, value) => {
    setValues({ ...values, [prop]: value })
  }

  return (
    <Container component="main">
      <Grid component={'form'} onSubmit={onSubmit} item xs={12}>
        <Paper sx={{ p: 2, width: '100%' }}>
          <Grid container sx={{ width: '100%' }} spacing={2}>
            <Grid item xs={12}>
              <ShortFormControl
                name={'name'}
                title={t('Name')}
                value={values.name}
                handleChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <ShortFormControl
                name={'uid'}
                title={t('UID')}
                value={values.uid}
                handleChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="type-select-label">{t('Type')}</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={values.type}
                  label={t('Type')}
                  onChange={handleChange('type')}
                >
                  <MenuItem value={'premium'}>{t('provider-type-premium')}</MenuItem>
                  <MenuItem value={'micropayments'}>{t('provider-type-micropayments')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <ShortFormControl
                name={'user'}
                title={t('User')}
                value={values.user}
                handleChange={handleChange}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <ShortFormControl
                name={'password'}
                title={t('Password')}
                value={values.password}
                handleChange={handleChange}/>
            </Grid>
          </Grid>
        </Paper>
        <Box sx={{ my: 2, px: 1 }}>
          <Button type={'submit'} variant={'contained'}>{t('Save')}</Button>
          {!isNaN(providerId) && (<Button type={'button'} color={'error'} variant={'contained'} sx={{ ml: 2 }}
                                          onClick={() => { setShownDeleteDialog(true) }}>{t('Delete')}</Button>)}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, width: '100%' }}>
          {!isNaN(providerId) && <BillingCodesListWidget providerId={parseInt(providerId)}/>}
        </Paper>
      </Grid>
      <AlertSimpleDialog
        title={t('Delete provider')}
        message={t('delete-confirm-msg', { name: values.name })}
        open={shownDeleteDialog}
        onClose={handleCloseDeleteDialog}
      />
    </Container>
  )

}