import React, { useState }       from 'react'
import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Typography
}                                from '@mui/material'
import BaseTable, { BaseSearch } from '../../components/base-table'
import { useApi }                from '../../utils/api'
import { Link as RouterLink }    from 'react-router-dom'
import { useTranslation }        from 'react-i18next'
import Page                      from '../../components/Page'
import Iconify                   from '../../components/Iconify'

export default function WhitelistPage() {

  const api = useApi()
  const { t } = useTranslation()
  const [query, setQuery] = useState('')

  const columns = [
    { field: 'telNumber', headerName: t('Tel. number'), sortable: false, filterable: false, flex: 1, renderCell: (cell) => {
      return <Link component={RouterLink} to={`/dashboard/whitelist/${cell.row.id}`}>{cell.value}</Link>
    }},
    { field: 'note', headerName: t('Note'), sortable: false, filterable: false, flex: 2 },
  ];

  return (<Page title={t('Whitelist')}>
    <Container>
      <Stack direction="row" alignItems="center" mb={5} spacing={2}>
        <Typography variant="h4" gutterBottom>{t('Whitelist')}</Typography>
        <BaseSearch query={query} onQueryChanged={(query) => setQuery(query)} />
        <Box flex={1} />
        <Button variant="contained"
                component={RouterLink}
                to="/dashboard/whitelist/add"
                startIcon={<Iconify icon="material-symbols:add" />}>
          {t('Add')}
        </Button>
      </Stack>
      <BaseTable
        columns={columns}
        query={query}
        loadCallback={async (query, page, pageSize) => {
          try {
            return await api.whitelist.list(query, page, pageSize)
          } catch (e) {
            console.log('Failed to load whitelist', e)
            return []
          }
        }}
      />
    </Container>
  </Page>)

}